import React, { useState } from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import MenuIcon from "@mui/icons-material/Menu"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import Container from "@mui/material/Container"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import logo from "../../assets/images/logo.svg"
import Link from "@mui/material/Link"

const pages = [
	{ text: "Service", link: "service" },
	{ text: "About us", link: "about-us" },
	{ text: "Portfolio", link: "portfolio" },
	{ text: "Contacts", link: "contacts" },
]

function Header() {
	const [menuToggle, setMenuToggle] = useState(false)

	const list = (
		<Box
			role="presentation"
			onClick={() => setMenuToggle(!menuToggle)}
			onKeyDown={() => setMenuToggle(!menuToggle)}
		>
			<List>
				{pages.map((page) => (
					<Link href={`#${page.link}`} sx={{ textDecoration: "none" }}>
						<ListItem key={page.text} disablePadding>
							<ListItemButton>
								<Typography
									variant="body2"
									color="#1c1e20"
									sx={{
										fontSize: "18px",
										lineHeight: "23.4px",
										fontWeight: "500",
										margin: "10px 40px",
									}}
								>
									{page.text}
								</Typography>
							</ListItemButton>
						</ListItem>
					</Link>
				))}
				<Link
					sx={{ textDecoration: "none" }}
					target="_blank"
					href="https://www.linkedin.com/company/vapour-ltd/"
				>
					<ListItem disablePadding>
						<ListItemButton
							sx={{ alignItems: "center", justifyContent: "center" }}
						>
							<Box>
								<IconButton size="large" disableRipple>
									<LinkedInIcon sx={{ color: "#4281AF" }} />
								</IconButton>
							</Box>
						</ListItemButton>
					</ListItem>
				</Link>
			</List>
		</Box>
	)

	return (
		<AppBar
			position="fixed"
			sx={{
				backgroundColor: "#fff",
				height: { xs: "110px", md: "90px" },
				justifyContent: "center",
				boxShadow: "none",
			}}
		>
			<Container sx={{ maxWidth: "1290px" }}>
				<Toolbar disableGutters>
					<Box
						component="span"
						sx={{
							mr: 1,
							display: "flex",
							flexGrow: "1",
							height: { xs: "58px", md: "76px" },
						}}
					>
						<Link href="#">
							<img src={logo} alt="logo" style={{ height: "100%" }} />
						</Link>
					</Box>

					{pages.map((page) => (
						<Typography
							variant="p"
							noWrap
							component="a"
							href={`#${page.link}`}
							sx={{
								fontSize: "20px",
								lineHeight: "24px",
								marginRight: "50px",
								display: { xs: "none", md: "flex" },
								color: "#1C1E20",
								textDecoration: "none",
							}}
						>
							{page.text}
						</Typography>
					))}

					<Box sx={{ display: { xs: "none", md: "flex" } }}>
						<Link
							sx={{ textDecoration: "none" }}
							target="_blank"
							href="https://www.linkedin.com/company/vapour-ltd/"
						>
							<IconButton size="large" disableRipple>
								<LinkedInIcon sx={{ color: "#4281AF" }} />
							</IconButton>
						</Link>
					</Box>

					<Box sx={{ display: { xs: "flex", md: "none" } }}>
						<IconButton
							size="large"
							disableRipple
							onClick={() => setMenuToggle(true)}
						>
							<MenuIcon sx={{ color: "#1C1E20" }} />
						</IconButton>
					</Box>
				</Toolbar>
			</Container>
			<SwipeableDrawer
				anchor={"right"}
				open={menuToggle}
				onClose={() => setMenuToggle(!menuToggle)}
			>
				{list}
			</SwipeableDrawer>
		</AppBar>
	)
}
export default Header
