import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const THEME = createTheme({
	typography: {
		fontFamily: `"Sarabun", "Helvetica", "Arial", sans-serif`,
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
	},
	palette: {
		primary: {
			main: "#4281AF",
		},
		secondary: {
			main: "#BD3A33",
		},
	},
	components: {
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					body1: "p",
					body2: "span",
				},
			},
		},
	},
})

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={THEME}>
			<App />
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById("root"),
)
