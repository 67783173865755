import React, { useState } from "react"
import Box from "@mui/material/Box"
import { Container } from "@mui/system"
import { Card, CardContent, Grid, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import about from "../../assets/images/about.png"
import aboutMin from "../../assets/images/about-min.png"
import mission from "../../assets/images/mission.jpg"
import sustainability from "../../assets/images/sustainability.jpg"

const CustomBox = styled(Box)((theme) => ({
	transition: "all 200ms ease-in",
	position: "relative",
	borderRadius: "10px",
	borderTopLeftRadius: "0px",
	"&.active": {
		background: "#4281AF",
		color: "#fff !important",
	},
	"&:before": {
		transition: "all 200ms ease-in",
		content: '" "',
		borderStyle: "solid",
		borderWidth: "0 20px 22px 0",
		borderColor: "transparent",
		position: "absolute",
		left: "-20px",
		top: "0",
	},
	"&.active:before": {
		borderColor: "transparent #4281AF transparent transparent",
	},
	"& .boxTitle": {
		transition: "all 200ms ease-in",
		color: "#BD3A33",
	},
	"&.active .boxTitle": {
		color: "#fff",
	},
}))
const CustomAboutUsBox = styled(Box)((theme) => ({
	backgroundImage: `url(${aboutMin})`,
	"@media (min-width: 550px)": {
		backgroundImage: `url(${about})`,
		backgroundPosition: "-300px 0",
	},
	"@media (min-width: 900px)": {
		backgroundImage: `url(${about})`,
		backgroundPosition: "top",
	},
}))

function About() {
	const [activeElement, setActiveElement] = useState("mission")

	return (
		<>
			<CustomAboutUsBox
				id="about-us"
				sx={{
					backgroundRepeat: "no-repeat",
					width: "100%",
					backgroundSize: "cover",
				}}
			>
				<Container sx={{ maxWidth: "1290px" }}>
					<Grid
						container
						sx={{
							paddingTop: "60px",
							paddingBottom: "60px",
							opacity: "0.9",
						}}
					>
						<Grid item xs={12} md={6}></Grid>
						<Grid
							item
							xs={12}
							md={6}
							sx={{ marginTop: { xs: "320px", md: "0" } }}
						>
							<Card
								sx={{
									maxWidth: { sx: "100%", md: "630px" },
									padding: "60px 45px",
									borderRadius: "10px",
								}}
							>
								<CardContent sx={{ maxWidth: 630, padding: "0" }}>
									<Typography
										gutterBottom
										variant="h5"
										component="div"
										sx={{
											fontSize: "48px",
											lineHeight: "57.6px",
											fontWeight: "600",
										}}
									>
										About us
									</Typography>
									<Typography
										variant="body2"
										color="primary"
										sx={{
											fontSize: "18px",
											lineHeight: "25.2px",
											fontWeight: "400",
										}}
									>
										VAPOUR OÜ
									</Typography>
									<Typography
										variant="body2"
										sx={{
											fontSize: "18px",
											lineHeight: "25.2px",
											fontWeight: "400",
										}}
									>
										{" "}
										is an engineering office in the process industry and we
										offer engineering services, design and consulting for heat
										exchangers, pressure vessels, non-pressure tanks and other
										steel construction. Our main business areas are power
										plants, pulp and paper mills, oil refineries, chemical and
										petrochemical industries. We are working together with
										different manufacturers to provide turnkey solutions.
									</Typography>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Container>
			</CustomAboutUsBox>
			<Box>
				<Container sx={{ maxWidth: "1290px" }}>
					<Grid
						container
						sx={{
							paddingTop: "60px",
							paddingBottom: "60px",
							opacity: "0.9",
						}}
					>
						<Grid item xs={12} md={6}>
							<Box
								sx={{
									height: "100%",
									maxHeight: { xs: "230px", md: "inherit" },
								}}
							>
								<img
									src={activeElement === "mission" ? mission : sustainability}
									style={{
										width: "100%",
										height: "100%",
										objectFit: "cover",
										borderRadius: "10px",
									}}
									alt={
										activeElement === "mission" ? "mission" : "sustainability"
									}
								/>
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							sx={{ marginTop: { xs: "30px", md: "0" } }}
						>
							<CustomBox
								sx={{
									marginLeft: "30px",
									padding: "30px 45px",
								}}
								onMouseEnter={() => setActiveElement("mission")}
								className={activeElement === "mission" ? "active" : ""}
							>
								<Typography
									gutterBottom
									className="boxTitle"
									sx={{
										fontSize: "36px",
										lineHeight: "50.4px",
										fontWeight: "600",
									}}
								>
									Mission
								</Typography>
								<Typography
									variant="body2"
									sx={{
										fontSize: "18px",
										lineHeight: "25.2px",
										fontWeight: "400",
									}}
								>
									VAPOUR is committed to creating added value for our customers
									through best quality, innovation, and flexibility. Our highest
									goal is to focus on customer needs and offer the best
									solution.
								</Typography>
							</CustomBox>
							<CustomBox
								sx={{ marginLeft: "30px", padding: "30px 45px" }}
								onMouseEnter={() => setActiveElement("sustainability")}
								className={activeElement === "sustainability" ? "active" : ""}
							>
								<Typography
									gutterBottom
									className="boxTitle"
									sx={{
										fontSize: "36px",
										lineHeight: "50.4px",
										fontWeight: "600",
									}}
								>
									Sustainability
								</Typography>
								<Typography
									variant="body2"
									sx={{
										fontSize: "18px",
										lineHeight: "25.2px",
										fontWeight: "400",
									}}
								>
									VAPOUR is concerned with the future and looking for
									improvements to become a more climate neutral company and
									reduce our CO2 footprint.
								</Typography>
							</CustomBox>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</>
	)
}
export default About
