import React from "react"
import Box from "@mui/material/Box"
import { Container } from "@mui/system"
import { Tab, Tabs, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import * as images00 from "../../assets/images/heatExchangers"
import * as images01 from "../../assets/images/pressureVessels"
import * as images02 from "../../assets/images/nonPressureTanks"
import * as images03 from "../../assets/images/scrubbers"
import * as images04 from "../../assets/images/others"
import Slider from "react-slick"

const images0 = [
	images00.Img1,
	images00.Img2,
	images00.Img3,
	images00.Img4,
	images00.Img5,
	images00.Img6,
	images00.Img7,
	images00.Img8,
	images00.Img9,
	images00.Img10,
	images00.Img11,
	images00.Img12,
	images00.Img13,
	images00.Img14,
	images00.Img15,
	images00.Img16,
	images00.Img17,
	images00.Img18,
	images00.Img19,
	images00.Img20,
	images00.Img21,
	images00.Img22,
	images00.Img23,
	images00.Img24,
	images00.Img25,
	images00.Img26,
	images00.Img27,
	images00.Img28,
	images00.Img29,
	images00.Img30,
	images00.Img31,
	images00.Img32,
]

const images1 = [
	images01.Img1,
	images01.Img2,
	images01.Img3,
	images01.Img4,
	images01.Img5,
	images01.Img6,
	images01.Img7,
	images01.Img8,
	images01.Img9,
	images01.Img10,
	images01.Img11,
	images01.Img12,
	images01.Img13,
	images01.Img14,
	images01.Img15,
	images01.Img16,
	images01.Img17,
	images01.Img18,
]

const images2 = [
	images02.Img1,
	images02.Img2,
	images02.Img3,
	images02.Img4,
	images02.Img5,
	images02.Img6,
	images02.Img7,
	images02.Img8,
	images02.Img9,
	images02.Img10,
	images02.Img11,
	images02.Img12,
	images02.Img13,
	images02.Img14,
	images02.Img15,
	images02.Img16,
]

const images3 = [images03.Img1, images03.Img2, images03.Img3, images03.Img4]
const images4 = [
	images04.Img1,
	images04.Img2,
	images04.Img3,
	images04.Img4,
	images04.Img5,
	images04.Img6,
	images04.Img7,
	images04.Img8,
]

const settings = {
	dots: true,
	infinite: true,
	slidesToShow: 4,
	arrows: false,
	slidesToScroll: 4,
	initialSlide: 0,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 860,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
		{
			breakpoint: 540,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
		// {
		// 	breakpoint: 580,
		// 	settings: {
		// 		slidesToShow: 1,
		// 		slidesToScroll: 1,
		// 	},
		// },
	],
}

const CustomSlider = styled(Slider)(() => ({
	"& .slick-slide img": {
		objectFit: "fill",
		maxWidth: "300px",
		width: "calc(100% - 30px)",
		height: "360px",
		margin: "auto",
		borderRadius: "10px",
	},
	"& .slick-dots": {
		bottom: "auto",
	},
}))

const CustomTabs = styled(Tabs)(() => ({
	"& .MuiTabs-scroller": {
		position: "inherit !important",
	},
	"& .MuiTabs-indicator": {
		display: "none",
	},
	"& .MuiTabs-flexContainer": {
		justifyContent: "space-between",
	},
}))

const CustomTab = styled(Tab)(() => ({
	margin: "10px",
	maxWidth: "220px",
	width: "100%",
	borderRadius: "10px",
	height: "50px",
	transition: "all 200ms ease-in",
	backgroundColor: "transparent",
	border: "2px solid #4281AF",
	"&:hover": {
		color: "#fff",
		backgroundColor: "#4281AF",
	},
	"&.Mui-selected": {
		color: "#fff",
		backgroundColor: "#4281AF",
	},
	"&.Mui-selected svg path": {
		fill: "#fff",
	},
	"&:hover svg path": {
		fill: "#fff",
	},
}))

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	)
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	}
}

function Services() {
	const [value, setValue] = React.useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<Container
			id="portfolio"
			sx={{
				maxWidth: "1290px",
				marginBottom: "90px",
			}}
		>
			<Box sx={{ width: "100%", textAlign: "center" }}>
				<Typography
					sx={{
						fontSize: "48px",
						fontWeight: "600",
						lineHeight: "57.6px",
						width: "100%",
						textAlign: "center",
					}}
				>
					Our recent works
				</Typography>
			</Box>
			<Box sx={{ width: "100%", paddingTop: "60px" }}>
				<Box sx={{ marginBottom: "40px" }}>
					<CustomTabs
						value={value}
						onChange={handleChange}
						variant="scrollable"
						scrollButtons="auto"
						allowScrollButtonsMobile
						aria-label="basic tabs example"
					>
						<CustomTab label="Heat exchangers" {...a11yProps(0)} />
						<CustomTab label="Pressure vessels" {...a11yProps(1)} />
						<CustomTab label="Non-Pressure Tanks" {...a11yProps(2)} />
						<CustomTab label="Scrubbers" {...a11yProps(3)} />
						<CustomTab label="Others" {...a11yProps(4)} />
					</CustomTabs>
				</Box>
				<TabPanel value={value} index={0}>
					<CustomSlider {...settings}>
						{images0.map((image, index) => (
							<Box sx={{ margin: "0", outline: "none" }}>
								<img src={image} alt={"image" + index} />
							</Box>
						))}
					</CustomSlider>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<CustomSlider {...settings}>
						{images1.map((image, index) => (
							<Box sx={{ margin: "0", outline: "none" }}>
								<img src={image} alt={"image" + index} />
							</Box>
						))}
					</CustomSlider>
				</TabPanel>
				<TabPanel value={value} index={2}>
					<CustomSlider {...settings}>
						{images2.map((image, index) => (
							<Box sx={{ margin: "0", outline: "none" }}>
								<img src={image} alt={"image" + index} />
							</Box>
						))}
					</CustomSlider>
				</TabPanel>
				<TabPanel value={value} index={3}>
					<CustomSlider {...settings}>
						{images3.map((image, index) => (
							<Box sx={{ margin: "0", outline: "none" }}>
								<img src={image} alt={"image" + index} />
							</Box>
						))}
					</CustomSlider>
				</TabPanel>
				<TabPanel value={value} index={4}>
					<CustomSlider {...settings}>
						{images4.map((image, index) => (
							<Box sx={{ margin: "0", outline: "none" }}>
								<img src={image} alt={"image" + index} />
							</Box>
						))}
					</CustomSlider>
				</TabPanel>
			</Box>
		</Container>
	)
}
export default Services
