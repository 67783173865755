import "./App.css"
import React from "react"
import { Header, Hero, Services, About, Works, Footer } from "./containers"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

function App() {
	return (
		<>
			<Header />
			<Hero />
			<Services />
			<About />
			<Works />
			<Footer />
		</>
	)
}

export default App
