import React from "react"
import Box from "@mui/material/Box"
import { Container } from "@mui/system"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import hero from "../../assets/images/hero.jpg"
import hero2 from "../../assets/images/hero2.png"
import { ReactComponent as ChemicalIndustry } from "../../assets/images/chemicalIndustry.svg"
import { ReactComponent as PulpPaper } from "../../assets/images/pulpPaper.svg"
import { ReactComponent as EnergyPower } from "../../assets/images/energyPower.svg"
import Grid from "@mui/material/Grid"
import SouthEastIcon from "@mui/icons-material/SouthEast"

function Hero() {
	return (
		<Box sx={{ maxWidth: "100%" }}>
			<Container sx={{ maxWidth: "1290px", marginBottom: "70px" }}>
				<Grid container>
					<Grid item xs={12} md={9} sx={{ marginTop: "80px" }}>
						<Typography
							sx={{
								fontSize: { xs: "42px", md: "58px" },
								fontWeight: "600",
								lineHeight: { xs: "50.4px", md: "69.6px" },
							}}
						>
							Meeting Your Expectations by providing the{" "}
							<Typography
								sx={{
									fontSize: { xs: "42px", md: "58px" },
									fontWeight: "600",
									lineHeight: { xs: "50.4px", md: "69.6px" },
								}}
								color="primary"
								component="body1"
							>
								Highest Quality Engineering
							</Typography>
						</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						md={3}
						sx={{
							display: "flex",
							alignItems: "flex-end",
							justifyContent: { xs: "flex-start", md: "flex-end" },
							marginTop: { xs: "60px", md: "0" },
						}}
					>
						<Button
							variant="contained"
							sx={{ maxHeight: "70px", borderRadius: "10px" }}
							href="#service"
						>
							<Typography
								sx={{
									fontSize: "18px",
									fontWeight: "600",
									lineHeight: "21.6px",
									whiteSpace: "nowrap",
									margin: "24px 30px",
									display: "flex",
									justifyContent: "center",
									alignItems: "flex-end",
								}}
							>
								Our services
								<SouthEastIcon
									sx={{ width: "20px", height: "20px", marginLeft: "20px" }}
								/>
							</Typography>
						</Button>
					</Grid>
				</Grid>
			</Container>
			<Box sx={{ height: { xs: "230px", md: "480px" } }}>
				<img
					src={hero}
					style={{ width: "100%", height: "100%", objectFit: "cover" }}
					alt="hero"
				/>
			</Box>
			<Box
				sx={{
					backgroundImage: `url(${hero2})`,
					backgroundColor: "#4281AF",
					backgroundPosition: "center",
				}}
			>
				<Container sx={{ maxWidth: "1290px" }}>
					<Grid container sx={{ paddingTop: "60px", paddingBottom: "60px" }}>
						<Grid
							item
							xs={12}
							sx={{
								marginBottom: "70px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Typography
								sx={{
									fontSize: "24px",
									fontWeight: "500",
									lineHeight: "33.6px",
									maxWidth: "740px",
									textAlign: "center",
								}}
								color="white"
							>
								VAPOUR provides a truly personal approach combined with a
								passion for engineering. As an approved vendor for many end
								customers and EPC contractors, we design products for a variety
								of industries including chemical, petrochemical, oil and gas,
								environmental, power and utility industries.
							</Typography>
						</Grid>
						<Grid container>
							<Grid
								item
								xs={12}
								md={4}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
									textAlign: "center",
								}}
							>
								<EnergyPower />
								<Typography
									sx={{
										fontSize: "18px",
										fontWeight: "400",
										lineHeight: "25.2px",
									}}
									color="white"
								>
									<Typography
										sx={{
											fontSize: "20px",
											fontWeight: "600",
											lineHeight: "25.2px",
											textTransform: "uppercase",
										}}
										component="h6"
									>
										Energy and heat generation
									</Typography>
									Power plants, Bioenergy plants, CHP plants, Nuclear power
									plants, Boiler houses
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								md={4}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
									textAlign: "center",
									margin: { xs: "65px 0", md: "inherit" },
								}}
							>
								<PulpPaper />
								<Typography
									sx={{
										fontSize: "18px",
										fontWeight: "400",
										lineHeight: "25.2px",
									}}
									color="white"
								>
									<Typography
										sx={{
											fontSize: "20px",
											fontWeight: "600",
											lineHeight: "25.2px",
											textTransform: "uppercase",
										}}
										component="h6"
									>
										Pulp and paper industry
									</Typography>
									Cooking plants, Recovery boiler plants, Evaporator plants.
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								md={4}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
									textAlign: "center",
								}}
							>
								<ChemicalIndustry />
								<Typography
									sx={{
										fontSize: "18px",
										fontWeight: "400",
										lineHeight: "25.2px",
									}}
									color="white"
								>
									<Typography
										sx={{
											fontSize: "20px",
											fontWeight: "600",
											lineHeight: "25.2px",
											textTransform: "uppercase",
										}}
										component="h6"
									>
										Petrochemical and chemical industry
									</Typography>
									Refineries, LNG terminals, Chemical plants
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Box>
	)
}
export default Hero
