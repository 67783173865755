import React from "react"
import Box from "@mui/material/Box"
import { Container } from "@mui/system"
import { Grid, Tab, Tabs, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { ReactComponent as HeatExchanger } from "../../assets/images/heatExchanger.svg"
import { ReactComponent as PressureVessel } from "../../assets/images/pressureVessel.svg"
import { ReactComponent as NonPressureVessel } from "../../assets/images/nonPressureVessel.svg"
import { ReactComponent as SteelStructures } from "../../assets/images/steelStructures.svg"
import { ReactComponent as ConsultingExpertise } from "../../assets/images/consultingExpertise.svg"
import { ReactComponent as Measuring } from "../../assets/images/measuring.svg"
import Slider from "react-slick"
import * as images00 from "../../assets/images/images0"
import * as images01 from "../../assets/images/images1"
import * as images02 from "../../assets/images/images2"
// import * as images03 from "../../assets/images/images3"
import * as images04 from "../../assets/images/images4"
import * as images05 from "../../assets/images/images5"

const images0 = [images00.Img0, images00.Img1, images00.Img2, images00.Img3]
const images1 = [images01.Img0]
const images2 = [images02.Img0, images02.Img1, images02.Img2]
const images3 = []
const images4 = [images04.Img0, images04.Img1]
const images5 = [images05.Img0, images05.Img1]

const settings = {
	dots: true,
	fade: true,
	arrows: false,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
}

const CustomSlider = styled(Slider)(() => ({
	"& .slick-slide img": {
		maxHeight: "340px",
		// maxWidth: "630px",
		width: "100%",
		height: "100%",
	},
}))

const CustomTabs = styled(Tabs)(() => ({
	"& .MuiTabs-scroller": {
		position: "inherit !important",
	},
	"& .MuiTabs-indicator": {
		display: "none",
	},
	"& .MuiTabs-flexContainer": {
		justifyContent: "space-between",
	},
}))

const CustomTab = styled(Tab)(() => ({
	maxWidth: "185px",
	width: "100%",
	borderRadius: "10px",
	height: "190px",
	transition: "all 200ms ease-in",
	backgroundColor: "#fff",
	"&:hover": {
		color: "#fff",
		backgroundColor: "#CA615C",
	},
	"&.Mui-selected": {
		color: "#fff",
		backgroundColor: "#CA615C",
	},
	"&.Mui-selected svg path": {
		fill: "#fff",
	},
	"&:hover svg path": {
		fill: "#fff",
	},
}))

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	}
}

function Services() {
	const [value, setValue] = React.useState(0)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	return (
		<Container
			id="service"
			sx={{
				maxWidth: "1290px",
				marginBottom: "60px",
				marginTop: "60px",
			}}
		>
			<Box sx={{ width: "100%", textAlign: "center" }}>
				<Typography
					sx={{
						fontSize: "48px",
						fontWeight: "600",
						lineHeight: "57.6px",
						width: "100%",
						textAlign: "center",
					}}
				>
					Our services
				</Typography>
			</Box>
			<Box sx={{ width: "100%", paddingTop: "60px" }}>
				<Box>
					<CustomTabs
						value={value}
						onChange={handleChange}
						variant="scrollable"
						allowScrollButtonsMobile
						scrollButtons="auto"
						aria-label="basic tabs example"
					>
						<CustomTab
							icon={<HeatExchanger fill="red" />}
							label="Heat exchangers"
							{...a11yProps(0)}
						/>
						<CustomTab
							icon={<PressureVessel />}
							label="Pressure vessels"
							{...a11yProps(1)}
						/>
						<CustomTab
							icon={<NonPressureVessel />}
							label="Non-pressure tanks"
							{...a11yProps(2)}
						/>
						<CustomTab
							icon={<SteelStructures />}
							label="Other steel structure"
							{...a11yProps(2)}
						/>
						<CustomTab
							icon={<ConsultingExpertise />}
							label="Consulting and expertise"
							{...a11yProps(2)}
						/>
						<CustomTab
							icon={<Measuring />}
							label="Measuring"
							{...a11yProps(2)}
						/>
					</CustomTabs>
				</Box>
				<TabPanel value={value} index={0}>
					<Grid container sx={{ marginTop: "70px" }}>
						<Grid
							item
							xs={12}
							md={6}
							sx={{
								justifyContent: "center",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Typography
								sx={{
									marginBottom: "18px",
									fontSize: "24px",
									fontWeight: "600",
									lineHeight: "31.2px",
								}}
							>
								Heat exchangers
							</Typography>
							<Typography
								sx={{
									fontSize: "18px",
									fontWeight: "400",
									lineHeight: "25.2px",
									width: { xs: "100%", md: "80%" },
								}}
							>
								We have long experience in thermal calculations considering all
								relevant standards for tubular heat exchangers (TEMA, HEI) and
								standards for pressure vessel equipment. We are able to design
								shell and tube heat exchangers for power plants, pulp mills,
								chemical and petrochemical industries. During heat exchangers’
								design, we consider all key aspects including fouling, vibration
								problems, corrosion, and erosion.
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomSlider {...settings}>
								{images0.map((image, index) => (
									<Box
										sx={{
											margin: { xs: "30px 0 0 0", md: "0" },
											outline: "none",
											maxHeight: { md: "340px" },
											maxWidth: { xs: "100%", md: "630px" },
										}}
									>
										<img
											src={image}
											style={{
												height: "100%",
												width: "100%",
												objectFit: "cover",
												borderRadius: "10px",
											}}
											alt={"image" + index}
										/>
									</Box>
								))}
							</CustomSlider>
						</Grid>
					</Grid>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<Grid container sx={{ marginTop: "70px" }}>
						<Grid
							item
							xs={12}
							md={6}
							sx={{
								justifyContent: "center",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Typography
								sx={{
									marginBottom: "18px",
									fontSize: "24px",
									fontWeight: "600",
									lineHeight: "31.2px",
								}}
							>
								Pressure vessels
							</Typography>
							<Typography
								sx={{
									fontSize: "18px",
									fontWeight: "400",
									lineHeight: "25.2px",
									width: { xs: "100%", md: "80%" },
								}}
							>
								VAPOUR provides a wide variety of services related to the design
								and technical advice for pressure vessels. We can offer design
								for feed water tanks (deaerators) that will meet all
								requirements for feed water degasification. To meet and confirm
								all the requirements of standards, we are able to provide code
								calculation according to EN 13445, ASME Sec VIII Div. 1 & 2, AD
								2000, GOST 34347. For non-standard pressure components or a
								difficult case, we provide finite element analysis (FEA).
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomSlider {...settings}>
								{images1.map((image, index) => (
									<Box
										sx={{
											margin: { xs: "30px 0 0 0", md: "0" },
											outline: "none",
											maxHeight: { md: "340px" },
											maxWidth: { xs: "100%", md: "630px" },
										}}
									>
										<img
											src={image}
											style={{
												height: "360px",
												width: "100%",
												objectFit: "cover",
												borderRadius: "10px",
											}}
											alt={"image" + index}
										/>
									</Box>
								))}
							</CustomSlider>
						</Grid>
					</Grid>
				</TabPanel>
				<TabPanel value={value} index={2}>
					<Grid container sx={{ marginTop: "70px" }}>
						<Grid
							item
							xs={12}
							md={6}
							sx={{
								justifyContent: "center",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Typography
								sx={{
									marginBottom: "18px",
									fontSize: "24px",
									fontWeight: "600",
									lineHeight: "31.2px",
								}}
							>
								Non-pressure tanks
							</Typography>
							<Typography
								sx={{
									fontSize: "18px",
									fontWeight: "400",
									lineHeight: "25.2px",
									width: { xs: "100%", md: "80%" },
								}}
							>
								We also design non-pressure and low-pressure storage tanks and
								silos. Our design of non-pressure tanks is based on EN 14015, EN
								1993-4-1 and EN 12285 standards. For all designed equipment we
								provide code calculations or FEA to ensure conformance to all
								relevant standards.
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomSlider {...settings}>
								{images2.map((image, index) => (
									<Box
										sx={{
											margin: { xs: "30px 0 0 0", md: "0" },
											outline: "none",
											maxHeight: { md: "340px" },
											maxWidth: { xs: "100%", md: "630px" },
										}}
									>
										<img
											src={image}
											style={{
												height: "360px",
												width: "100%",
												objectFit: "cover",
												borderRadius: "10px",
											}}
											alt={"image" + index}
										/>
									</Box>
								))}
							</CustomSlider>
						</Grid>
					</Grid>
				</TabPanel>
				<TabPanel value={value} index={3}>
					<Grid container sx={{ marginTop: "70px" }}>
						<Grid
							item
							xs={12}
							md={6}
							sx={{
								justifyContent: "center",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Typography
								sx={{
									marginBottom: "18px",
									fontSize: "24px",
									fontWeight: "600",
									lineHeight: "31.2px",
								}}
							>
								Other steel structure
							</Typography>
							<Typography
								sx={{
									fontSize: "18px",
									fontWeight: "400",
									lineHeight: "25.2px",
									width: { xs: "100%", md: "80%" },
								}}
							>
								To maximize the ease with which our customers can implement our
								solutions, we can develop documentation for additional equipment
								supplied with the main equipment. Therefore, we provide design
								for stairs, platforms, ladders, chimneys, lifting equipment, and
								transportation frames considering EN 14122, EN 1993-3-2 and EN
								13155 standards.
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomSlider {...settings}>
								{images3.map((image, index) => (
									<Box
										sx={{
											margin: { xs: "30px 0 0 0", md: "0" },
											outline: "none",
											maxHeight: { md: "340px" },
											maxWidth: { xs: "100%", md: "630px" },
										}}
									>
										<img
											src={image}
											style={{
												height: "360px",
												width: "100%",
												objectFit: "cover",
												borderRadius: "10px",
											}}
											alt={"image" + index}
										/>
									</Box>
								))}
							</CustomSlider>
						</Grid>
					</Grid>
				</TabPanel>
				<TabPanel value={value} index={4}>
					<Grid container sx={{ marginTop: "70px" }}>
						<Grid
							item
							xs={12}
							md={6}
							sx={{
								justifyContent: "center",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Typography
								sx={{
									marginBottom: "18px",
									fontSize: "24px",
									fontWeight: "600",
									lineHeight: "31.2px",
								}}
							>
								Consulting and expertise
							</Typography>
							<Typography
								sx={{
									fontSize: "18px",
									fontWeight: "400",
									lineHeight: "25.2px",
									width: { xs: "100%", md: "80%" },
								}}
							>
								We support our customers in all stages of projects from planning
								to installation and start-up. We make all efforts to help our
								customers to reach the highest performance at the best
								economical values.
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomSlider {...settings}>
								{images4.map((image, index) => (
									<Box
										sx={{
											margin: { xs: "30px 0 0 0", md: "0" },
											outline: "none",
											maxHeight: { md: "340px" },
											maxWidth: { xs: "100%", md: "630px" },
										}}
									>
										<img
											src={image}
											style={{
												height: "360px",
												width: "100%",
												objectFit: "cover",
												borderRadius: "10px",
											}}
											alt={"image" + index}
										/>
									</Box>
								))}
							</CustomSlider>
						</Grid>
					</Grid>
				</TabPanel>
				<TabPanel value={value} index={5}>
					<Grid container sx={{ marginTop: "70px" }}>
						<Grid
							item
							xs={12}
							md={6}
							sx={{
								justifyContent: "center",
								display: "flex",
								flexDirection: "column",
							}}
						>
							<Typography
								sx={{
									marginBottom: "18px",
									fontSize: "24px",
									fontWeight: "600",
									lineHeight: "31.2px",
								}}
							>
								Measuring
							</Typography>
							<Typography
								sx={{
									fontSize: "18px",
									fontWeight: "400",
									lineHeight: "25.2px",
									width: { xs: "100%", md: "80%" },
								}}
							>
								In the case of retrofit projects, we can provide a measuring
								service for existing equipment to ensure that new-build
								equipment will fit in the old place.
							</Typography>
						</Grid>
						<Grid item xs={12} md={6}>
							<CustomSlider {...settings}>
								{images5.map((image, index) => (
									<Box
										sx={{
											margin: { xs: "30px 0 0 0", md: "0" },
											outline: "none",
											maxHeight: { md: "340px" },
											maxWidth: { xs: "100%", md: "630px" },
										}}
									>
										<img
											src={image}
											style={{
												height: "360px",
												width: "100%",
												objectFit: "cover",
												borderRadius: "10px",
											}}
											alt={"image" + index}
										/>
									</Box>
								))}
							</CustomSlider>
						</Grid>
					</Grid>
				</TabPanel>
			</Box>
		</Container>
	)
}
export default Services
